require('./bootstrap');

// Swiper
import Swiper from 'swiper/bundle';;

//glihtbox
import GLightbox from 'glightbox';

// VUE CALENDAR
import Vue from 'vue';
import customCalendar from "./customCalendar.vue";
import VCalendar from 'v-calendar';
import AOS from 'aos';

window.aos = AOS;

window.Swiper = Swiper;
window.GLightbox = GLightbox;
AOS.init();

window.Vue = Vue;
// Use v-calendar & v-date-picker components
Vue.config.productionTip = false;

// Use v-calendar & v-date-picker components
Vue.use(VCalendar);

Vue.component('custom-calendar', customCalendar);
