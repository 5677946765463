<template>
  <div class="w-max mx-auto">
    <div class="flex items-center">
      <span class="w-2 h-2 bg-red-200"></span>
      <p @click="logMe" class="ml-2 text-xs">Obsazeno</p>
    </div>
    <v-calendar
      v-cloak
      class="custom-calendar max-w-full"
      :attributes="attributes"
      disable-page-swipe
      is-expanded
    >
      <template v-slot:day-content="{ day, attributes }">
        <div class="flex flex-col items-center justify-center h-full w-full z-10 overflow-hidden" :class="(attributes && attributes.length != 0) ? 'bg-red-200' : ''">
          <span class="text-sm text-gray-900 font-bold">{{ day.day }}</span>
        </div>
      </template>
    </v-calendar>
  </div>
</template>

<script>
export default {
  props: {
    reservations: Array,
  },
  data() {
    return {
      attributes: [],
    };
  },
  methods: {
    logMe()
    {
      console.log(this.attributes);
    },
    parseData() {
      let newData = [];
      this.reservations.forEach((reservation, key) => {
        let d = reservation.date.split("/");
        newData.push({
          key: key,
          customData: {
            title: reservation.title
          },
          dates: new Date(d[2], (d[1] - 1), d[0])
        })
      });

      this.attributes = newData;
    }
 },
 beforeMount() {
   this.parseData();
 }
};
</script>
